.dropdown-filter{
  ul{
    padding-top: 0;

    .entry{
      padding: 5px;
    }
  }
  .close{
    text-align: right;
    background: white;
    padding: 5px 5px;
    padding-bottom: 0;
  }
}