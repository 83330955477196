@import "/src/styles/mixins";

.frame-with-filter > .header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: var(--mui-palette-primary-light);
  color: var(--mui-palette-common-white);
  align-items: center;
  justify-content: space-between;
  padding: 0.1vh 1vw;
  min-height: 40px;

  .highlighted {
    .MuiChip-filled {
      background: var(--mui-palette-common-white);
      font-weight: 500;
    }

    @include media-to-S {
      display: none;
    }
  }

  .title {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      transform: scale(1.02);
      filter: contrast(1.3);
    }

    > img {
      height: min(calc(1em + 2vw), 2.5em);
      margin-right: 1vh;
    }

    > h1 {
      flex-grow: 0;
      margin: 0;
      margin-right: 2vh;
      font-size: min(calc(1em + 1.8vw), 2.5em);
    }
  }

  > nav{
    @include media-to-S {
      display: none;
    }

    align-self: flex-end;
    padding: 5px;
  }

  .tabs {
    .MuiTab-labelIcon {
      min-height: 60px;
      padding: 4px;
    }
  }
}
