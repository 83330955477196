@import "/src/styles/mixins";

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5vw;
  flex-shrink: 0;

  background: var(--mui-palette-primary-dark);
  color: var(--mui-palette-grey-200);

  @include media-to-S {
    display: none;
  }

  a {
    color: var(--mui-palette-grey-200);
    text-decoration: none;
    padding-right: 15px;
    position: relative;

    .spx-icon {
      position: absolute;
      width: 10px;
      right: 0;
      top: 0;
    }

    &:hover {
      color: white;
      transform: scale(1.1);
      transform-origin: right;
      transition: .1s;
    }
  }

  .legend {
    display: flex;
    align-items: center;

    @include media-to-S {
      display: none;
    }

    h5 {
      margin: 0;
      margin-right: 1.5em;
      font-style: italic;
    }

    .icon {
      width: 16px;
      margin-right: 0.2em;
    }
  }
}