$media-XS: 350px;
$media-S: 800px;
$media-L: 1024px;
$media-XL: 1480px;

@mixin media-from-S{
  @media screen and (min-width: $media-S){
    @content
  }
}

@mixin media-to-XS{
  @media screen and (max-width: $media-XS){
    @content
  }
}

@mixin media-to-S{
  @media screen and (max-width: $media-S){
    @content
  }
}

@mixin media-S-to-L{
  @media screen and (min-width: $media-S) and (max-width: $media-L){
    @content
  }
}

@mixin media-to-L{
  @media screen and (max-width: $media-L){
    @content
  }
}

@mixin media-from-L{
  @media screen and (min-width: $media-L){
    @content
  }
}

@mixin media-L-to-XL{
  @media screen and (min-width: $media-L) and (max-width: $media-XL){
    @content
  }
}

@mixin media-to-XL{
  @media screen and (max-width: $media-XL){
    @content
  }
}

@mixin media-from-XL{
  @media screen and (min-width: $media-XL){
    @content
  }
}
