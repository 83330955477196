@import "/src/styles/vars.module";

$padding: 30px;
$chip-gap: 5px;
$max-width: 50em;

.group-pop{
  max-width: $max-width;

  .MuiChip-root {
    margin-right: $chip-gap;
    margin-bottom: $chip-gap;
    cursor: default;

    height: auto;

    &.MuiChip-label {
      display: block;
      whiteSpace: normal;
    }

    > span {
      text-wrap: initial;
    }

    &:hover {
      filter: contrast(130%);
    }
  }

  > * {
    padding: 30px ($padding - $chip-gap) 0 $padding;
  }

  .pop-header{
    top: 0;
    position: sticky;
    padding: $padding;
    padding-left: $padding + 20px;
    color: white;
    display: flex;
    align-items: center;

    .tipo {
      position: absolute;
      opacity: .4;
      left: 5px;
      margin-top: auto;
      margin-bottom: auto;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      font-size: 1em;
    }

    .close-button {
      position: absolute;
      right: 5px;
      top: 5px;
      color: white;
    }

    .group-icon {
      font-size: 48px;
      margin-right: $padding;
    }

    h2{
      font-size: 35px;
      margin: 0;
      padding-right: 20px;
    }
  }

  h3{
    font-size: 12px;
    margin: 0 0 1em;
  }

  .group-website {
    text-align: right;
    padding-top: 10px;

    a {
      position: relative;
      padding-right: 15px;
      display: inline-block;
      margin-left: 1em;

      &:hover {
        transform: scale(1.05);
        transition: .05s;
        filter: brightness(140%);
      }

      .spx-icon {
        position: absolute;
        right: 0;
        width: 10px;
      }
    }
  }

  .group-website + div {
    padding-top: 20px;
  }

  .ips {
    .MuiChip-root {
      color: white;
    }
  }

  .anep {
    .area-ref {
      font-weight: bolder;
      margin-right: 0.5em;
      opacity: .7;
    }

    .MuiChip-root {
      cursor: pointer;
    }
  }

  .lines {
    .MuiChip-root {
      outline: 1px solid var(--mui-palette-grey-400);
      color: black;
      background: none;

      &:hover {
        filter: none;
      }
    }
  }

  .uni{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: $padding;
    padding-right: $padding;

    img{
      height: 50px;
    }
  }
}