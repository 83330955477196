@import "src/styles/vars.module";
@import "/src/styles/mixins";

.frame-with-filter {
  display: grid;
  grid-template-rows: min-content 1fr min-content;
  background: var(--mui-palette-grey-100);
  position: fixed;
  width: 100vw;
  height: 100vh;

  > .middle {
    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: 1fr;

    > .left {
      width: 20vw;
      min-width: 300px;
      max-width: 450px;
      display: flex;
      flex-direction: column;
    }

    > .right {
      position: relative;
      display: flex;
      flex-grow: 1;
    }
  }

  &.view-chart {
    @include media-to-S {
      > .middle {
        grid-template-columns: 1fr;

        > .left {
          width: initial;
          max-width: initial;
        }

        > .right {
          display: none;
        }
      }
    }
  }

  &.view-text {
    @include media-to-S {
      > .middle {
        grid-template-columns: 1fr;

        > .left {
          display: none;
        }

        > .right {
        }
      }
    }
  }

}