@import "/src/styles/mixins";

$color-area: var(--mui-palette-primary-main);
$color-subarea: var(--mui-palette-secondary-main);
$color-uni: var(--mui-palette-success-main);

.filter{
  display: flex;
  flex-direction: column;
  margin: 0.5vw;
  align-items: stretch;
  flex-grow: 1;
  background-color: var(--mui-palette-background-paper);
  color: var(--mui-palette-text-primary);
  border-radius: 4px;
  border: 1px solid var(--mui-palette-divider);
  overflow: hidden;

  @include media-to-S {
    margin: 0;
    border-radius: 0;
    border: 0;
  }

  .filter-menus {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-left: 10px;

    .title {
      flex-grow: 1;
    }
  }

  .filter-chips {
    margin-left: 10px;

    .MuiChip-root {
      margin-right: 10px;
      margin-bottom: 10px;
      color: white;
    }

    .area-chip {
      background: $color-area;
    }

    .subarea-chip {
      background: $color-subarea;
    }

    .uni-chip {
      background: $color-uni;
    }
  }

  .search {
    padding: 1.5vh 1vw 0.2vh;
    display: flex;
    flex-direction: column;

    .search-edit {
      width: 100%;
    }

    .MuiInput-root {
      font-weight: bold;
      color: var(--mui-palette-error-main);
    }
  }
}

.MuiMenu-root {
  .area {
    font-weight: bold;
  }

  .subarea {
    padding-left: 20px;
  }

  .MuiChip-root {
    color: white;
  }

  .area-chip {
    background: $color-area;
  }

  .subarea-chip {
    background: $color-subarea;
  }

  .uni-chip {
    background: $color-uni;
  }

}