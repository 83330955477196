@import "/src/styles/mixins";

$paddingX: 2vh;

.text-view-container{
  position: relative;
  width: 100%;
  height: 100%;

  .text-view{
    position: absolute;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;

    .buttons {
      background-color: white;
      top: 0;
      position: sticky;
      z-index: 100;

      display: none;
      @include media-to-S {
        display: flex;
      }
    }

    .small-screen {
      display: none;
      @include media-to-S {
        display: flex;
      }

      justify-content: center;

      .filter-summary {
        margin: 1em .1em 4em;
        display: block;
      }

      .highlighted {
        margin: 1em 0;

        .MuiButtonBase-root {
          background: white;
        }
      }
    }

    .main-area{

      > h2{
        margin-bottom: 2em;
        color: white;
        padding: 8vh $paddingX;
        font-size: 50px;
        background-color: black;

        &:first-child {
          margin-top: 0.5vw;
        }

        .text {
          padding: 0.5em;
          position: relative;
        }

        .anep-ref{
          margin-right: $paddingX;
          font-weight: bolder;
          opacity: .5;
        }
      }
    }

    .subarea{
      > h3 {
        margin-bottom: 2em;
        padding: 2vh $paddingX;
        font-size: 30px;

        .anep-ref{
          margin-right: $paddingX;
          font-weight: bolder;
          opacity: .5;
        }
      }
    }
  }
}