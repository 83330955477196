@import "/src/styles/mixins";

.filter-summary{
  display: flex;

  @include media-to-L {
    display: none;
  }

  @include media-to-S {
    display: none;
  }

  .label {
    margin-bottom: 20px;
  }

  .summary-separator {
    margin-bottom: 2em;
  }

  .badge {
    margin-right: 15px;

    > .MuiChip-filled {
      background: var(--mui-palette-common-white);
      font-weight: 500;
    }

    > .MuiBadge-badge {
      background: black;
      color: white;
    }

    &.areas > .MuiChip-filled {
      color: var(--mui-palette-secondary-dark);
    }

    &.subareas > .MuiChip-filled {
      color: var(--mui-palette-success-dark);
    }

    &.groups > .MuiChip-filled {
      color: var(--mui-palette-primary-dark);
    }
  }

}