.chart-view-container {
  box-sizing: border-box;
  height: 100%;
  width: 100%;
  padding: 0.5vw;
  padding-left: 0;
  overflow: hidden;

  .chart-view {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
  }
}
