$padding: 30px;
$chip-gap: 5px;

.cell-pop {
  max-width: 50em;

  .MuiChip-root {
    margin-right: $chip-gap;
    margin-bottom: $chip-gap;
    cursor: default;

    height: auto;

    &.MuiChip-label {
      display: block;
      whiteSpace: normal;
    }

    > span {
      text-wrap: initial;
    }

    &:hover {
      filter: brightness(150%);
    }
  }

  > * {
    padding: 30px ($padding - $chip-gap) 0 $padding;
  }

  .pop-header{
    top: 0;
    position: sticky;
    display: flex;
    align-items: center;

    padding: $padding;
    padding-left: $padding + 20px;
    color: white;

    .tipo {
      position: absolute;
      opacity: .4;
      left: 5px;
      margin-top: auto;
      margin-bottom: auto;
      writing-mode: vertical-lr;
      transform: rotate(180deg);
      font-size: 1em;
    }

    .close-button {
      position: absolute;
      right: 5px;
      top: 5px;
      color: white;
    }

    .anep{
      font-size: 40px;
      margin-right: $padding;
      font-weight: bolder;
      opacity: .5;
    }

    h2{
      font-size: 35px;
      margin: 0;
    }
  }

  h3{

    svg{
      margin-right: 5px;
    }

    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 12px;
    margin: 0 0 1em;
  }

  .parent-area {
    .MuiChip-root {
      cursor: pointer;
    }
  }

  .groups {
    padding-bottom: $padding;

    .MuiChip-root {
      cursor: pointer;
      border: 1px solid transparent;
      color: white;

      &:hover {
        border: 1px solid black;
      }
    }
  }

}