.grid > .cell{
  box-sizing: border-box;
  overflow: hidden;
  container-type: inline-size;
  display: flex;
  margin: -.5px;
  //border: 1px solid white;

  &.null-cell {
    border: none;
  }

  // Celda NO nula
  &.content-cell {
    cursor: pointer;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: 5%;

    &.area-focus {
      filter: brightness(120%);
    }

    &.dimmed {
      opacity: .05;
    }

    &.no-border-top {
      border-top: none;
    }

    &.no-border-right {
      border-right: none;
    }

    &.no-border-bottom {
      border-bottom: none;
    }

    &.no-border-left {
      border-left: none;
    }

  }

  &.title-cell {
    position: relative;

    > * {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }

    > .background {
      opacity: .3;
    }

    > .content {

      padding: 5%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      p{
        margin: 0;
      }

      .count {
        position: absolute;
        left: 7px;
        top: 7px;
        left: 7cqw;
        top: 7cqw;
        font-size: 1vw;
        font-size: 10cqw;
      }

      .title {
        margin: 0;
        font-size: 1vw;
        font-size: 11cqw;
        word-break: break-word;
        text-align: center;

        //text-shadow:
        //        0.07em 0 #444,
        //        0 0.07em #444,
        //        -0.07em 0 #444,
        //        0 -0.07em #444;
      }

      .ref {
        margin-bottom: 0.2em;
        font-weight: bolder;
        font-size: 1vw;
        font-size: 18cqw;
      }

    }
  }

  // Celda con grupos
  &.leaf-cell {

    &:not(.leaf-area):hover {
      filter: brightness(125%);
    }

    .cell-header {
      margin-bottom: 0.4em;
      font-size: 1vw;
      font-size: 10cqw;
      opacity: .7;
      //color: white;
      text-align: center;

      .ref {
        font-weight: bold;
        margin-right: 0.5em;
      }

    }


    &.leaf-area {
      .ref {
        opacity: .4;
        font-weight: normal;
      }
    }

    > .groups {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      //flex-grow: 1;
      align-content: center;
      justify-content: center;
      gap: 0;

      .group {
        padding: 3.3% 2.5%;
        position: relative;
        cursor: pointer;

        &.dimmed {
          opacity: .15;
        }
      }

      .group-icon {
        position: absolute;
        transform-origin: 50% 50%;
        transform: scale(.7);
        left: 0;
        top: 0;

      }

      :hover {
        .group-icon {
          transform: scale(1);
        }
      }
    }
  }
}
