@import "/src/styles/mixins";

$padding: 2em;
$gap: 3em;

.home {
  position: fixed;
  width: 100vw;
  height: 100vh;

  &:before{
    content: "";
    position: fixed;
    width: 100vw;
    height: 100vh;
    background: url('home-background.webp') center center no-repeat;
    background-size:cover;
    filter: blur(5px) brightness(.6);
  }

  .frame{
    position: absolute;
    width: fit-content;
    height: fit-content;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow: hidden;

    @include media-to-S {
      max-width: 90%;
      border-radius: 0;
    }
  }

  .footer {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  .header{
    display: flex;
    flex-direction: row;
    background: var(--mui-palette-primary-light);
    color: var(--mui-palette-common-white);
    align-items: center;
    padding: 0 1em;
    flex-shrink: 0;

    @include media-to-S {
      flex-direction: column;
      justify-content: center;
    }

    > img{
      height: 3vh;
      padding: 2vh;
      margin-right: 0.5px;

      @include media-to-S {
        height: 3vh;
      }
    }

    > h1{
      flex-grow: 0;
      text-transform: lowercase;

      @include media-to-S {
        text-align: center;
        margin-top: 0;
        text-transform: none;
      }
    }
  }

  .content{
    padding: $gap $padding;
    font-size: 1.3em;
  }

  .buttons{
    padding: 0 $padding/2 $padding/2 ;
    display: flex;
    flex-direction: row-reverse;
  }

}
