@import "/src/styles/mixins";
@import "/src/styles/vars.module";

$paddingY: 10px;
$paddingX: 10px;
$chip-gap: 10px;
$max-width: 50em;

.text-view .group{
  max-width: 50em;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 100px;
  background: var(--mui-palette-grey-50);
  border-radius: 5px;
  overflow: hidden;

  @include media-to-S {
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
  }

  .MuiChip-root {
    margin-right: $chip-gap;
    margin-bottom: $chip-gap;
    cursor: default;

    height: auto;

    &.MuiChip-label {
      display: block;
      whiteSpace: normal;
    }

    > span {
      text-wrap: initial;
    }
  }

  > * {
    padding: 30px ($paddingY - $chip-gap) 0 $paddingY;
    max-width: $max-width;
  }

  .pop-header{
    padding: $paddingY $paddingX;
    color: white;
    max-width: none;

    .repeated {
      text-align: right;
      position: relative;
      left: 10px;
      top: 10px;

      .MuiChip-root {
        background: white;
      }
    }

    .title {
      display: flex;
      align-items: center;
      max-width: $max-width;
    }

    .group-icon {
      font-size: 32px;
      margin-right: $paddingX;
    }

    h4{
      font-size: 25px;
      margin: 0;
    }
  }

  h5{
    font-size: 12px;
    margin: 0 0 1em;
  }

  .group-website {
    text-align: right;
    padding-top: 10px;
    padding-right: $paddingX;

    a {
      margin-left: 1em;
      position: relative;
      padding-right: 15px;
      display: inline-block;

      &:hover {
        transform: scale(1.05);
        transition: .05s;
        filter: brightness(140%);
      }

      .spx-icon {
        position: absolute;
        right: 0;
        width: 10px;
      }
    }
  }

  .group-website + div {
    padding-top: 10px;
  }

  .ips {
    .MuiChip-root {
      color: white;
    }
  }

  .anep {
    .area-ref {
      font-weight: bolder;
      margin-right: 0.5em;
      opacity: .7;
    }
  }

  .lines {
    display: flex;
    flex-direction: column;
    align-items: start;

    .MuiChip-root {
      outline: 1px solid var(--mui-palette-grey-400);
      color: black;
      background: none;

      &:hover {
        filter: none;
      }
    }
  }

  .uni{
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    padding-bottom: $paddingY;
    padding-right: $paddingX;

    img{
      height: 50px;
    }
  }
}