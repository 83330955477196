@import "/src/styles/mixins";

.filter-matches{
  flex-grow: 1;
  position: relative;

  .container{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 16px;
    overflow: auto;

    @include media-to-S {
      bottom: 0;
    }
  }

  .small-screen {
    display: none;
    @include media-to-S {
      display: block;
    }
    top: 0;
    position: sticky;
    background: white;
    z-index: 100;

    .buttons {
      display: flex;
      justify-content: flex-end;
    }
  }

  .section{
    display: flex;
    align-items: center;
    padding: 2em 16px 0.5em;
    margin: 0;
    cursor: pointer;
    color: var(--mui-palette-grey-700);

    @include media-to-S {
      padding: 1em 16px 0.25em;
    }

    > span {
      flex-grow: 1;
    }

    .count{
      background: var(--mui-palette-grey-700);
      margin-right: 0.7em;
      position: relative;
      top: -1px;
    }

    &.folded {
      opacity: .8;
    }

    &:hover {
      color: black;

      > svg {
        transform-origin: 50% 50%;
        transform: scale(1.1);
      }

      .count {
        background: black;
      }
    }

  }
}
