$color-uab: #008539;
$color-upf: #d70926;
$color-uc3m: #000e78;
$color-uam: #2a2a2a;

:export {
  color_uab: $color-uab;
  color_upf: $color-upf;
  color_uc3m: $color-uc3m;
  color_uam: $color-uam;
}

.color-uab {
  color: $color-uab;
}

.color-uam {
  color: $color-uam;
}

.color-upf {
  color: $color-upf;
}

.color-uc3m {
  color: $color-uc3m;
}


.background-uab {
  background: $color-uab;
}

.background-uam {
  background: $color-uam;
}

.background-upf {
  background: $color-upf;
}

.background-uc3m {
  background: $color-uc3m;
}

